import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import BaseScanApi from "../scripts/bscscan";
import CoinGeckoAPI from "../scripts/coingecko";
import ImoCache from "../scripts/cache";

export const ApiContext = createContext(null);

export const ApiProvider = ({ children }) => {
  const [response, setResponse] = useState(null);
  const [apiProviders, setApiProviders] = useState([]);
  const [gcCache, setGcCache] = useState(null);
  const [baseCache, setBaseCache] = useState(null);

  useEffect(() => {
    const apis = [new CoinGeckoAPI("imo"), new BaseScanApi()];
    const gcCache = new ImoCache(apis[0], "gc");
    gcCache.hydrate();

    const baseCache = new ImoCache(apis[1], "bsc");
    baseCache.hydrate();

    setApiProviders(apis);
    setGcCache(gcCache);
    setBaseCache(baseCache);
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (baseCache != null && gcCache != null) {
        const response = {
          base: await baseCache.get(),
          cg: await gcCache.get(),
        };
        setResponse(response);
      }
    }
    fetchData();
  }, [baseCache, gcCache]);

  return (
    <ApiContext.Provider
      value={{ api: response, apiProviders, gcCache, baseCache: baseCache }}
    >
      {children}
    </ApiContext.Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
