import React,  { useContext} from 'react';
import './StakingExplanation.css';
import { Language } from '../../Context';
import { useTranslation, Trans } from 'react-i18next'


const StakingExplanation = () => {
  const { LanguageUse } = useContext(Language)
  const { t } = useTranslation()
  return (
    <div className="explanation-container">
  <h2>{t('what-is-staking')}</h2>
  <p>{t('staking-explanation')}</p>

  <h2>{t('balancer-ve8020-model')}</h2>
  <p>{t('balancer-model-explanation-1')}</p>
  <p>{t('balancer-model-explanation-2')}</p>
  <p>
    <Trans i18nKey="balancer-model-explanation-3">
      Alternativement, vous pouvez obtenir des tokens BPT sur <a href="https://balancer.fi/pools/base/v2/0x007bb7a4bfc214df06474e39142288e99540f2b3000200000000000000000191/add-liquidity" target="_blank" rel="noopener noreferrer">Balancer</a>, et staker ces tokens BPT dans la pool.
    </Trans>
  </p>

  <h2>{t('pool-details')}</h2>
  <p>{t('pool-characteristics')}</p>
  <ul>
    <li>
      <strong>{t('start-date')}:</strong> {t('start-date-description')}
    </li>
    <li>
      <strong>{t('end-date')}:</strong> {t('end-date-description')}
    </li>
    <li>
      <strong>{t('hardcap')}:</strong> {t('hardcap-description')}
    </li>
    <li>
      <strong>{t('fixed-apy')}:</strong> {t('fixed-apy-description')}
    </li>
    <li>
      <strong>{t('lock-duration')}:</strong> {t('lock-duration-description')}
    </li>
  </ul>
</div>
  );
};

export default StakingExplanation;
