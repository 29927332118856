import React, { useState, useEffect, useContext, useRef } from "react";
import { getBurns } from '../../scripts/burnTokens.mjs'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Language } from '../../Context'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns';


const SupplyChart = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [burntData, setBurntData] = useState([]); // State for burnt tokens
    const initialSupply = 20000000; // Initial supply of 20 million

    

    const chartRef = useRef(null);
    const { language } = useContext(Language);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const burns = await getBurns();
                const formattedData = burns.map((burn, index) => ({
                    x: index,//new Date(burn.timestamp * 1000), // Convert timestamp to milliseconds
                    y: burn.totalSupply,
                    date : new Date(burn.timestamp * 1000),
                    hash: burn.hash,
                    supplyBurnt: burn.supplyBurnt,
                    url_basescan: burn.url_basescan
                }));
                setChartData(formattedData);

                // Calculate burnt tokens data
                const burntTokensData = formattedData.map(dataPoint => ({
                    x: dataPoint.x,
                    y: initialSupply - dataPoint.y
                }));
                setBurntData(burntTokensData);
                
            } catch (error) {
                console.error("Error fetching burn data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const options = {
        credits: {
            enabled: false  // Désactive le texte "Highcharts.com"
        },
        chart: {
            type: 'line',
            zoomType: 'x',
            height: 400,
            backgroundColor: 'rgb(29, 32, 45)',
            style: {
                fontFamily: 'Poppins, sans-serif'
            },
            borderRadius: 15,
            spacing: [20, 20, 20, 20]
        },
        title: {
            text: t('TotalSupplyOverTime').toUpperCase(),
            style: {
                color: '#fdfaf6',
                fontWeight: 600,
                fontSize: '14px',
                fontFamily: "'new-hero', sans-serif",
            },
            marginTop: 50,
            margin: 40
        },
        xAxis: {
            type: 'category',
            ordinal: false,
            labels: {
                style: {
                    color: '#FFFFFF' // Texte blanc
                },
                rotation: -45, // Rotation des dates
                y: 20, // Décalage vertical,
            },
            tooltip: {
                formatter: function() {
                  return `<b>Date:</b> ${new Date(this.point.date).toLocaleDateString()}<br/>
                          <b>Value:</b> ${this.point.y}`;
                }
              },
            gridLineColor: 'rgba(255, 255, 255, 0.1)',
            gridLineWidth: 1,
            gridLineDashStyle: 'dash',
            lineColor: 'rgba(255, 255, 255, 0.1)',    // Axis line color
            tickColor: 'rgba(255, 255, 255, 0.1)',    // Tick mark color
            tickWidth: 1,
           
            // Set categories based on the x-values of your data
            categories: chartData.map(point => {
                return new Date(point.date).toLocaleDateString();
              }),

        },
        yAxis: {
            title: {
                text: t('supplyChartYAxis'),
                style: {
                    color: '#5E5E5F',
                    fontFamily: "'new-hero', sans-serif" ,
                    fontWeight: 600
                }
            },
            tickInterval: 200000,
            max : 20000000,
            labels: {
                style: {
                    color: '#FFFFFF'
                },
                formatter: function() {
                    return this.value.toLocaleString(); // Format avec séparateurs de milliers
                }
            },
            gridLineColor: 'rgba(255, 255, 255, 0.1)',
            gridLineWidth: 1,
            gridLineDashStyle: 'dash',
            lineColor: 'rgba(255, 255, 255, 0.1)',    // Axis line color
            tickColor: '#E0E0E0',     // Tick mark color
            lineWidth: 1
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                point: {
                    events: {
                        click: function() {
                            window.open(this.url_basescan, '_blank');
                        }
                    }
                }
            }
        },
        series: [{
            name: t('supplyChartSeries'),
            color: '#FFFFFF', // Spark color
            lineWidth: 3, // Bolder line
            marker: {
                fillColor: '#FFFFFF',
                lineWidth: 2,
                lineColor: '#FFFFFF',
                radius: 4,
            },
            data: chartData,
           
        }
        /*,
        {
            name: t('burntTokensSeries'),
            color: 'red', // Spark color
            lineWidth: 3, // Bolder line
            marker: {
                fillColor: 'red',
                lineWidth: 2,
                lineColor: 'red',
                radius: 4,
            },
            data: burntData // Use burnt tokens data here
        }*/
    
    ],
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        style: {
            color: '#FFFFFF'
        },
        formatter: function() {
            const point = this.point;
            const tokensBurned = initialSupply - point.y;
            return `
                <span style="display: inline-block; margin-right: 5px; width: 10px; height: 10px; background-color: #FFFFFF;"></span>
                <b>${t('tooltipChart.tokensBurned')} : </b>${point.supplyBurnt.toLocaleString()}<br/>
                <b>${t('tooltipChart.totalSupply')} : </b>${point.y.toLocaleString()}<br/>
                <b>${t('tooltipChart.txHash')} : </b>${point.hash}<br/>
                <b>${t('tooltipChart.clickToView')}`;
        },
        useHTML: true
    },
    rangeSelector: {
        buttons: [{
            type: 'month',
            count: 1,
            text: '1m'
        }, {
            type: 'month',
            count: 3,
            text: '3m'
        }, {
            type: 'month',
            count: 6,
            text: '6m'
        }, {
            type: 'year',
            count: 1,
            text: '1y'
        }, {
            type: 'all',
            text: 'All'
        }],
        forced : true,
        selected: 2, // Initially select the 6-month button
        inputEnabled: false, // Disable the date input field
        buttonTheme: { // Customize button appearance
            fill: 'none',
            stroke: 'none',
            'stroke-width': 0,
            r: 0,
            style: {
                color: '#fdfaf6', // Match your chart colors
                fontWeight: 'bold'
            },
            states: {
                hover: {
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 0,
                    style: {
                        color: '#ffcc00' // Highlight color
                    }
                },
                select: {
                    fill: 'none',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 0,
                    style: {
                        color: '#ffcc00' // Selected color
                    }
                }
            }
        }
    },
    };

    return (
        <div
        style={{
            margin: 'auto',
            width: '93%',
            maxWidth: '900px',
            borderRadius: '30px',
            marginTop: '50px',
            marginBottom: '70px',
            fontFamily: "'new-hero', sans-serif",
        }}
        >
            {isLoading ? (
                <p>Loading chart data...</p>
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    constructorType={'chart'}
                    updateArgs={[true, true, true]}
                    ref={chartRef}
                />
            )}
        </div>
    );
};

export default SupplyChart;