import React, { useContext } from 'react'

import { ReactComponent as BaseScan } from '../../Icons/PartnersBaseScanLogo.svg'
import { ReactComponent as Balancer } from '../../Icons/PartnersBalancerLogo.svg'
import { ReactComponent as CoinGecko } from '../../Icons/PartnersCoinGecko.svg'
import Dext from '../../images/Dext.png'
import { ReactComponent as CoinMarketCap } from '../../Icons/PartnersCoinMarketCap.svg'

import { Language } from '../../Context'

import './partners.css'

const Partners = () => {
     const { LanguageUse } = useContext(Language)
     const handleBaseScan = () => {
          window.open('https://basescan.org/token/0x5a7a2bf9ffae199f088b25837dcd7e115cf8e1bb')
     }
     const handleBalancerSwap = () => {
          window.open(
               'https://balancer.fi/swap/base/ETH/0x5a7a2bf9ffae199f088b25837dcd7e115cf8e1bb'
          )
     }
     const handleCoinGecko = () => {
          if (LanguageUse === 'en') {
               window.open('https://www.coingecko.com/en/coins/imo')
          } else {
               window.open('https://www.coingecko.com/fr/pièces/imo')
          }
     }
     const handleDext = () => {
          window.open('https://www.dextools.io/app/en/base/pair-explorer/0x007bb7a4bfc214df06474e39142288e99540f2b3-0x4200000000000000000000000000000000000006-0x5a7a2bf9ffae199f088b25837dcd7e115cf8e1bb?t=1729786529030')
     }
     const handleCoinMarketCap = () => {
          window.open('https://coinmarketcap.com/currencies/imo/')
     }
     return (
          <div className='partners'>
               <div className='partnersContainer'>
                    <div className='partnersItem' onClick={handleBaseScan}>
                         <BaseScan />
                    </div>
                    <div className='partnersItem' onClick={handleBalancerSwap}>
                         <Balancer />
                    </div>
                    <div className='partnersItem' onClick={handleCoinGecko}>
                         <CoinGecko />
                    </div>
                    <div className='partnersItem full' onClick={handleDext}>
                         <img src={Dext} alt='' />
                    </div>
                    <div className='partnersItem full' onClick={handleCoinMarketCap}>
                         <CoinMarketCap />
                    </div>
               </div>
          </div>
     )
}

export default Partners
