import React, { useState, useContext, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { Language } from "../../Context";
import useApi from "../../hooks/useApi";

import "./pieChart.css";

const PieChartClass = () => {
  //Test vercel
  const { t } = useTranslation();
  const { LanguageUse } = useContext(Language);
  const [Hover, setHover] = useState("out");
  const { api } = useApi();

  const [companies, setCompaniesAmount] = useState(null);
  const [devellopement, setDevellopementAmount] = useState(null);
  const [team1, setTeam1Amount] = useState(null);
  const [team2, setTeam2Amount] = useState(null);
  const [treasury, setTreasuryAmount] = useState(null);
  const [liquidity_reserve, setLiquidityReserveAmount] = useState(null);
  const [marketing, setMarketingAmount] = useState(null);
  const [ecosystem, setEcosystemAmount] = useState(null);

  const [burn, setBurnAmount] = useState(null);
  const [investors, setInvestorsAmount] = useState(null);
  const [liquidity, setLiquidityAmount] = useState(null);

  const [chartOptions, setChartOptions] = useState(null);

  const handlerMouseLeave = () => {
    setHover("out");
    setChartOptions({
      series: [
        {
          data: [
            {
              name: "investors",
              y: investors / 1,
              selected: false,
            },
            {
              name: "treasury",
              y: treasury * 1,
              selected: false,
            },
            {
              name: "liquidity",
              y: liquidity * 1,
              selected: false,
            },
            {
              name: "burn",
              y: burn * 1,
              selected: false,
            },
            {
              name: "liquidity_reserve",
              y: liquidity_reserve * 1,
              selected: false,
            },
            {
              name: "companies",
              y: companies / 1,
              selected: false,
            },
            {
              name: "team1",
              y: team1 * 1,
              selected: false,
            },
            {
              name: "team2",
              y: team2 * 1,
              selected: false,              
            },
            {
              name: "devellopement",
              y: devellopement * 1,
              selected: false,
            },
            {
              name: "marketing",
              y: marketing * 1,
              selected: false,
            },
            {
              name: "ecosystem",
              y: ecosystem * 1,
              selected: false,              
            },
          ],
        },
      ],
    });
  };

  useEffect(() => {
    if (api && api.base != null) {
      setCompaniesAmount(api.base.companies);
      setDevellopementAmount(api.base.devellopement);
      setTeam1Amount(api.base.team1);
      setTeam2Amount(api.base.team2);

      setBurnAmount(api.base.burn);
      setInvestorsAmount(api.base.investors);
      setLiquidityAmount(api.base.liquidity);
      setLiquidityReserveAmount(api.base.liquidity_reserve);
      setTreasuryAmount(api.base.treasury);
      setMarketingAmount(api.base.marketing);
      setEcosystemAmount(api.base.ecosystem);


    }
  }, [api]);

  useEffect(() => {
    setChartOptions({
      chart: {
        height: 350,
        width: 350,
        type: "pie",
        style: {
          fontFamily: "new-hero",
        },
      },
      legend: {
        align: "left",
        floating: true,
        backgroundColor: "red",
        verticalAlign: "middle",
        style: {
          zindex: 50,
          position: "absolute",
        },
        x: -90,
      },
      title: {
        text: "20 M",
        align: "center",
        verticalAlign: "middle",
        y: 35,
        style: {
          wordSpacing: -10,
          fontWeight: "bold",
          fontSize: 40,
          color: "#1D202D",
        },
      },
      subtitle: {
        text: "IMO",
        align: "center",
        verticalAlign: "middle",
        style: {
          fontWeight: "500",
          fontSize: 22,
          color: "#1D202D",
          opacity: 0.5,
        },
        y: 55,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          borderWidth: 3,
          slicedOffset: 10,
          innerSize: "98%",
          ignoreHiddenPoint: false,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
              normal: {
                enabled: true,
                brightness: 1,
                opacity: 1,
                animation: false,
              },
              select: {
                enabled: false,
              },
            },
          },
          shadow: false,
          dataLabels: {
            enabled: false,
          },

          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
            normal: {
              enabled: true,
              brightness: 1,
              opacity: 1,
              animation: false,
            },
            select: {
              enabled: false,
            },
          },
        },
        series: {
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
            normal: {
              enabled: true,
              brightness: 1,
              opacity: 1,
              animation: false,
            },
            select: {
              enabled: false,
            },
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          enableMouseTracking: true,
          showInLegend: false,
          states: {
            hover: {
              enabled: true,
              halo: {
                size: 15,
              },
            },
            inactive: {
              enabled: true,
              color: "black",
              opacity: 0.1,
            },
            normal: {
              enabled: true,
              brightness: 1,
              opacity: 1,
              animation: false,
            },
            select: {
              color: "black",
              opacity: 0.3,
              enabled: true,
            },
          },
          name: "",

          point: {
            events: {
              mouseOver: function () {
                random(this.name);
                /* handlerMouseLeave()
                                   if (this.name === 'investors') {
                                        handlerMouseOverInv()
                                   }
                                   if (this.name === 'companies') {
                                        handlerMouseOverCom()
                                   }
                                   if (this.name === 'liquidity') {
                                        handlerMouseOverLiq()
                                   }
                                   if (this.name === 'team') {
                                        handlerMouseOverTeam()
                                   }
                                   if (this.name === 'devellopement') {
                                        handlerMouseOverPar()
                                   }
                                   if (this.name === 'burn') {
                                        handlerMouseOverBurn()
                                   }*/
              },
              mouseOut: function () {
                /*setchartOptions({
                                        series: [
                                             {
                                                  enableMouseTracking: false,
                                             },
                                        ],
                                   })
                                   console.log('test')
                                   handlerMouseLeave()*/
              },
            },
          },
          events: {
            mouseOut: function () {
              handlerMouseLeave();
            },
          },

          data: [
            {
              name: "investors",
              y: investors / 1,
              selected: false,
            },
            {
              name: "treasury",
              y: treasury * 1,
              selected: false,
            },
            {
              name: "liquidity",
              y: liquidity * 1,
              selected: false,
            },
            {
              name: "burn",
              y: burn * 1,
              selected: false,
            },
            {
              name: "liquidity_reserve",
              y: liquidity_reserve * 1,
              selected: false,
            },
            {
              name: "companies",
              y: companies / 1,
              selected: false,
            },
            {
              name: "team1",
              y: team1 * 1,
              selected: false,
            },
            {
              name: "team2",
              y: team2 * 1,
              selected: false,              
            }
            ,
            {
              name: "devellopement",
              y: devellopement * 1,
              selected: false,
            },
            {
              name: "marketing",
              y: marketing * 1,
              selected: false,
            },
            {
              name: "ecosystem",
              y: ecosystem * 1,
              selected: false,              
            },
          ],
          size: "95%",
          innerSize: "70%",
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 100,
            },
            chartOptions: {
              series: [
                {},
                {
                  id: "versions",
                  dataLabels: {
                    enabled: false,
                  },
                },
              ],
            },
          },
        ],
      },
    });
  }, [burn, devellopement, team1, team2, ecosystem,liquidity_reserve, marketing, treasury, liquidity, investors, companies]);

  Highcharts.setOptions({
    colors: ["#1D202D", "#383951", "#585972", "#9A9BB9", "#CFD1F5", "#E6E8FF"],
  });

  const formatter = new Intl.NumberFormat("en");

  const random = (point) => {
    setHover(point);
  };

  
const generateChartData = (selectedName) => {
  return [
    {
      name: "investors",
      y: investors / 1,
      selected: selectedName !== "investors"
    },
    {
      name: "treasury",
      y: treasury * 1,
      selected: selectedName !== "treasury"
    },
    {
      name: "liquidity",
      y: liquidity * 1,
      selected: selectedName !== "liquidity"
    },
    {
      name: "burn",
      y: burn * 1,
      selected: selectedName !== "burn"
    },
    {
      name: "liquidity_reserve",
      y: liquidity_reserve * 1,
      selected: selectedName !== "liquidity_reserve"
    },
    {
      name: "companies",
      y: companies / 1,
      selected: selectedName !== "companies"
    },
    {
      name: "team1",
      y: team1 * 1,
      selected: selectedName !== "team1"
      
    },
    {
      name: "team2",
      y: team2 * 1,
      selected: selectedName !== "team2"
    },
    {
      name: "devellopement",
      y: devellopement * 1,
      selected: selectedName !== "devellopement"
      
    },
    {
      name: "marketing",
      y: marketing * 1,
      selected: selectedName !== "marketing"
    },
    {
      name: "ecosystem",
      y: ecosystem * 1,
      selected: selectedName !== "ecosystem"
    }
    ]
  }

  const handlerMouseOver = (name) => {
    setHover(name);
    setChartOptions({
      series: [{
        data: generateChartData(name)
      }]
    })
  }

  const handlerMouseOverInv = () => handlerMouseOver("investors")
  const handlerMouseOverCom = () => handlerMouseOver("companies")
  const handlerMouseOverLiq = () => handlerMouseOver("liquidity")
  const handlerMouseOverTeam1 = () => handlerMouseOver("team1")
  const handlerMouseOverTeam2 = () => handlerMouseOver("team2")
  const handlerMouseOverDev = () => handlerMouseOver("devellopement")
  const handlerMouseOverBurn = () => handlerMouseOver("burn")
  const handlerMouseOverTreasury = () => handlerMouseOver("treasury")
  const handlerMouseOverMarket = () => handlerMouseOver("marketing")
  const handlerMouseOverLiqReserve = () => handlerMouseOver("liquidity_reserve")
  const handlerMouseOverEco = () => handlerMouseOver("ecosystem")

  return (
    <div className="pieChart">
      <div className="pieChartContainer">
        <div className="pieChartTitle">{t("Tokenomics")}</div>
        <div className="pieChartChart">
          <div className="pieChartChartLeft">
            <div className="pieChartChartLeftList">
              <div
                className={
                  Hover === "investors" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverInv}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #1D202D" }}
                >
                  {t("Investors")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(investors)
                    : formatter
                      .format(investors)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverInv}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "treasury" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverTreasury}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #CFD1F5" }}
                >
                   {t("Treasury")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(treasury)
                    : formatter
                      .format(treasury)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverTreasury}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "liquidity" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverLiq}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #585972" }}
                >
                  {t("Liquidity")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(liquidity)
                    : formatter
                      .format(liquidity)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverLiq}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "burn" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverBurn}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #E6E8FF" }}
                >
                  {t("Burned")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(burn)
                    : formatter
                      .format(burn)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverBurn}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "liquidity_reserve" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverLiqReserve}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #CFD1F5" }}
                >
                   {t("Liquidity Reserve")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(liquidity_reserve)
                    : formatter
                      .format(liquidity_reserve)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverLiqReserve}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "companies" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverCom}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #383951" }}
                >
                  {t("Company")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(companies)
                    : formatter
                      .format(companies)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverCom}
                onMouseLeave={handlerMouseLeave}
              ></div>
              
              <div
                className={
                  Hover === "team1" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverTeam1}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #9A9BB9" }}
                >
                   {t("Team1Chart")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(team1)
                    : formatter
                      .format(team1)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverTeam1}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "team2" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverTeam2}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #9A9BB9" }}
                >
                  {t("Team2Chart")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(team2)
                    : formatter
                      .format(team2)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverTeam2}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "devellopement" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverDev}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #CFD1F5" }}
                >
                   {t("Devellopement")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(devellopement)
                    : formatter
                      .format(devellopement)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverDev}
                onMouseLeave={handlerMouseLeave}
              ></div>
              <div
                className={
                  Hover === "marketing" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverMarket}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #CFD1F5" }}
                >
                   {t("Marketing")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(marketing)
                    : formatter
                      .format(marketing)
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverMarket}
                onMouseLeave={handlerMouseLeave}
              ></div>
              
              
              <div
                className={
                  Hover === "ecosystem" || Hover === "out"
                    ? "pieChartChartLeftItem"
                    : "pieChartChartLeftItemNone"
                }
                onMouseOver={handlerMouseOverEco}
                onMouseLeave={handlerMouseLeave}
              >
                <div
                  className="pieChartChartLeftItemTitle"
                  style={{ borderLeft: "solid 4px #CFD1F5" }}
                >
                   {t("Ecosystem")}
                </div>
                <div className="pieChartChartLeftItemValue">
                  {LanguageUse === "en"
                    ? formatter.format(Math.trunc(ecosystem))
                    : formatter
                      .format(Math.trunc(ecosystem))
                      .replace(",", "'")
                      .replace(",", "'")
                      .replace(",", "'")}
                </div>
              </div>
              <div
                className="pieChartChartLeftItemLine"
                onMouseOver={handlerMouseOverEco}
                onMouseLeave={handlerMouseLeave}
              ></div>
              
              
            </div>
          </div>
          <div className="pieChartChartRight">
            <PieChart
              highcharts={Highcharts}
              options={chartOptions}
              updateArgs={[true]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChartClass;
