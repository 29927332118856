//const fetch = require('node-fetch');
//import fetch from 'node-fetch';
const apiKey = 'E695ZMC4IXGCV9IPI3Q2KMY4TTAZ4W7GUT'; // Replace with your BscScan API key
const tokenAddress = '0x5A7a2bf9fFae199f088B25837DcD7E115CF8E1bb'; // Replace with the ERC20 token address
const burnAddress = '0x0000000000000000000000000000000000000000'; // Common burn address
/** There are 3 addresses that can burn IMO tokens  */
const fromAddress1 = '0x5D5341923C7F261D5b6053881fD811f8BC4f8BF8'; 
const fromAddress2 = '0x27E20BD50106e3Fbc50A230bd5dC02D7793c7D84'; 
const fromAddress3 = '0x27E20BD50106e3Fbc50A230bd5dC02D7793c7D84'; 
const fromAddress4 = '0xe8831eAAb999ea3F051ed2fd31891ab4Ab259B0B';
const tokenDecimals = 18


export const getBurns = async () => {
    try {
        //Burns are made by 2 addresses so we need to get burns by these 2
        const url1 = `https://api.basescan.org/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${fromAddress1}&to=${burnAddress}&sort=asc&apikey=${apiKey}`;
        const url2 = `https://api.basescan.org/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${fromAddress2}&to=${burnAddress}&sort=asc&apikey=${apiKey}`;
        const url3 = `https://api.basescan.org/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${fromAddress3}&to=${burnAddress}&sort=asc&apikey=${apiKey}`;
        const url4 = `https://api.basescan.org/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${fromAddress4}&to=${burnAddress}&sort=asc&apikey=${apiKey}`;
        const response1 = await fetch(url1);
        const response2 = await fetch(url2);
        const response3 = await fetch(url3);
        const response4 = await fetch(url4);      
        const data1 = await response1.json();
        const data2 = await response2.json();
        const data3 = await response3.json();
        const data4 = await response4.json();

        // Filter and format transactions
        let result1, result2, result3, result4 = []
        result1 = data1.result
        result2 = data2.result
        result3 = data3.result
        result4 = data4.result
        console.log("result1 is ", result1)
        console.log("result2 is ", result2)
        console.log("result3 is ", result3)
        console.log("result4 is ", result4)
        let burnTransfers1 = result1.type !== 'string' && result1.length > 0  
          ?  result1.filter(tx => tx.to === burnAddress) 
          : [] 
            
        let burnTransfers2 = result2.type !== 'string' && result2.length > 0 
        ? result2.filter(tx => tx.to === burnAddress) 
        : [] 


        let burnTransfers3 = result3.type !== 'string' && result3.length > 0  
        ? result3.filter(tx => tx.to === burnAddress) 
        : [] 

        let burnTransfers4 = result4.type !== 'string' && result4.length > 0  
        ? result4.filter(tx => tx.to === burnAddress) 
        : [] 
        
        const burnTransfers_ = [...burnTransfers1, ...burnTransfers2, ...burnTransfers3, ...burnTransfers4]
        //Sort Burn transfers by date ASC
        burnTransfers_.sort((a, b) => a.timeStamp - b.timeStamp);
                    
        const burnTransfers = burnTransfers_
            .map(tx => {
                return {
                    timestamp: parseInt(tx.timeStamp),
                    date_fr: timestampToFrenchFormat(tx.timeStamp),
                    date_en: timestampToUSFormat(tx.timeStamp),
                    hash: tx.hash,
                    url_basescan: `https://basescan.org/tx/${tx.hash}`,
                    value: formatTokenValue(tx.value, tokenDecimals)
                }
            })    

        
        //Add the birth of IMO with initial Supply at the beginning of the array
        burnTransfers.unshift({
            timestamp: 1728740751,
            date_fr: timestampToFrenchFormat(1728740751),
            date_en: timestampToUSFormat(1728740751),
            hash: '0x99989f41fc2178b5014a5cfa8cb6f4b12a693123a276929804de4f32195e387f',
            url_basescan: `https://basescan.org/tx/0x99989f41fc2178b5014a5cfa8cb6f4b12a693123a276929804de4f32195e387f`,
            value: 0
        })

        let initialSupply = 20000000 
        let result = transformArray(burnTransfers, initialSupply);
        console.log("result is ", result)
        return result;

    } catch (error) {
        console.error('Error fetching filtered transfers:', error);
        throw error;
    }
};

//------------------------------------------------------------------------------------------
// Function tthat transforms the amount burnt by the remaining supply
function transformArray(arr, initialSupply) {
    let currentSupply = initialSupply;

    return arr.map((obj) => {
        currentSupply -= obj.value;
        return {
            timestamp: obj.timestamp,
            date_fr: obj.date_fr,
            date_en: obj.date_en,
            hash: obj.hash,
            url_basescan: obj.url_basescan,
            totalSupply: currentSupply,
            supplyBurnt: obj.value
        };
    });
}



//------------------------------------------------------------------------------------------
// Function to format token supply
function formatTokenValue(value, decimals) {
    let formattedValue = value / Math.pow(10, decimals);
    formattedValue = Math.round(formattedValue * 100) / 100; // Round to two decimal places

    // Check if rounding up requires an addition of only 0.01
    if (Math.ceil(formattedValue) - formattedValue === 0.01) {
        formattedValue = Math.ceil(formattedValue);
    }

    return formattedValue;
}

//------------------------------------------------------------------------------------------
const isValidTimestamp = (input) => {
    // Check if the input is a number
    if (typeof input !== 'number') {
        return false;
    }

    // Create a date object using the input
    const date = new Date(input);

    // Check if the date object represents a valid date
    // The value is valid if it's not 'Invalid Date' and the input equals the date's timestamp
    return !isNaN(date.getTime()) && date.getTime() === input;
}

//------------------------------------------------------------------------------------------
const timestampToFrenchFormat = (timestamp) => {
    const date = new Date(timestamp*1000);
    // Format the date in DD/MM/YYYY format using template literals
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    return formattedDate;
}

//------------------------------------------------------------------------------------------
const timestampToUSFormat = (timestamp) => {
    const date = new Date(timestamp*1000);    
    // Format the date in MM/DD/YYYY format using template literals
    const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
    return formattedDate;
}


// Usage (Try it with the command 'node src/scripts/burnTokens.js')
/*
(async () => {
    try {
        const transfers = await getBurns();
        console.log('Filtered Transfers:', transfers);
    } catch (error) {
        console.error('Error:', error);
    }
})();
*/