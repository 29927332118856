import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


import './properties.css'
import firstFlatlivingRoom from '../../images/1st_flat/livingRoom.jpg'
import firstFlatStakingCover from '../../images/1st_staking_flat.jpg'
import secondFlatStakingCover from '../../images/2nd_staking_flat.jpg'
import thirdFlatStakingCover from '../../images/3rd_staking_flat.jpg'

import { ReactComponent as FlatSizeIconLight } from "../../Icons/FlatSizeLight.svg";
import { ReactComponent as FlatPriceIconLight } from "../../Icons/FlatPriceLight.svg";
import { ReactComponent as FlatROIIconLight } from "../../Icons/FlatROILight.svg";
import { ReactComponent as FlatBurnIconLight } from "../../Icons/FlatBurnLight.svg";
import { ReactComponent as FlatSizeIconDark } from "../../Icons/FlatSizeDark.svg";
import { ReactComponent as FlatPriceIconDark } from "../../Icons/FlatPriceDark.svg";
import { ReactComponent as FlatROIIconDark } from "../../Icons/FlatROIDark.svg";
import { ReactComponent as FlatBurnIconDark } from "../../Icons/FlatBurnDark.svg";

import { Language } from '../../Context';

const Properties = () => {
     // const { t } = useTranslation()

     const { LanguageUse, setLanguageUse } = useContext(Language)
     const [Mobile, setMobile] = useState(false)

     const { t, i18n } = useTranslation()
     useEffect(() => {
          WindowChange()
          setLanguageUse(i18n.language)
     }, [setLanguageUse, i18n.language])

     const HandleMobileMenu = () => {
          setMobile(!Mobile)
          // HandlerImageHover()
     }

     const HandlerCloseMobileMenu = () => {
          setMobile(false)
     }

     const WindowChange = () => {
          if (window.innerWidth > 700) {
               setMobile(false)
          }
     }

     const handlerLanguageChange = (value) => {
          if (Mobile) {
               HandleMobileMenu()
          }
          if (LanguageUse !== value) {
               setLanguageUse(value)
               i18n.changeLanguage(value)
          }
     }

     const handleInformation = () => {
          if (Mobile) {
               HandleMobileMenu()
          }
          if (LanguageUse === 'en') {
               window.open('/docs/Propriete1_informations.pdf')
          } else {
               window.open('/docs/Property1_information.pdf')
          }
     }


     return (
          <div className='properties'>
               <div className='propertiesContainer'>
                    <div className='propertiesTitle'>{t('Properties')}</div>
                    <div className='propertiesList'>
                         <div className='propertiesItem '>
                              <div className='propertiesItemWrapper'>
                                   <div className='propertiesItemFront'>
                                        <div className='propertiesItemIcon'>
                                             <img className="firstFlatCoverPicture" src={firstFlatlivingRoom} alt=''></img>
                                        </div>
                                        <div className='propertiesItemText'>
                                             {t('Properties#1')}
                                             <div className='propertiesItemKeysInfos'>
                                                  <div className="flatType">
                                                       <FlatSizeIconLight className='flatKeyInfosIcon' />
                                                       <p>T2 / 3.5</p>
                                                  </div>
                                                  <div className="flatPrice">
                                                       <FlatPriceIconLight className='flatKeyInfosIcon' />
                                                       <p>CHF 320K</p>
                                                  </div>
                                                  <div className="flatRent">
                                                       <FlatBurnIconLight className='flatKeyInfosIcon' />
                                                       <p>$ 1'000</p>
                                                  </div>
                                                  <div className="flatROI">
                                                       <FlatROIIconLight className='flatKeyInfosIcon' />
                                                       <p>6.2%</p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className='propertiesItemBack'>
                                        <div className='propertiesMoreDetails' onClick={handleInformation}>{t('MoreDetails')}</div>
                                   </div>
                              </div>
                         </div>

                         <div className='horizontalSeparation'></div>

                         <div className='propertiesItem'>
                              <div className='propertiesItemWrapperStaking'>
                                   <div className='propertiesItemFront'>
                                        <div className='propertiesItemIcon'>
                                             <img className="coverPicture" src={firstFlatStakingCover} alt=''></img>
                                        </div>
                                        <div className='propertiesItemText'>
                                             {t('PropertiesStaking#1')}
                                             <div className='propertiesItemKeysInfosStaking'>
                                                  <div className="flatType">
                                                       <FlatSizeIconDark className='flatKeyInfosIcon' />
                                                       <p>T1 / 2.5</p>
                                                  </div>
                                                  <div className="flatRent">
                                                       <FlatBurnIconDark className='flatKeyInfosIcon' />
                                                       <p>$ 600</p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <a href={t('RealEstateStakingMediumLink')} target="_blank" className='stakingToken'> {t('RealEstateStaking')}</a>
                         </div>
                         <div className='propertiesItem'>
                              <div className='propertiesItemWrapperStaking'>
                                   <div className='propertiesItemFront'>
                                        <div className='propertiesItemIcon'>
                                             <img className="coverPicture" src={secondFlatStakingCover} alt=''></img>
                                        </div>
                                        <div className='propertiesItemText'>
                                             {t('PropertiesStaking#2')}
                                             <div className='propertiesItemKeysInfosStaking'>
                                                  <div className="flatType">
                                                       <FlatSizeIconDark className='flatKeyInfosIcon' />
                                                       <p>4 × T1 / 2.5</p>
                                                  </div>
                                                  <div className="flatRent">
                                                       <FlatBurnIconDark className='flatKeyInfosIcon' />
                                                       <p>$ 1'600</p>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <a href={t('RealEstateStakingMediumLink')} target="_blank" className='stakingToken'> {t('RealEstateStaking')}</a>

                         </div>

                         <div className='propertiesItem'>

                                   <div className='propertiesItemWrapperStaking'>
                                        <div className='propertiesItemFront'>
                                             <div className='propertiesItemIcon'>
                                                  <img className="coverPicture" src={thirdFlatStakingCover} alt=''></img>
                                             </div>
                                             <div className='propertiesItemText'>
                                                  {t('PropertiesStaking#3')}
                                                  <div className='propertiesItemKeysInfosStaking'>
                                                       <div className="flatType">
                                                       <FlatSizeIconDark className='flatKeyInfosIcon' />
                                                       <p>T1 / 3.5</p>
                                                       </div>
                                                       <div className="flatRent">
                                                       <FlatBurnIconDark className='flatKeyInfosIcon' />
                                                       <p>$ 900</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <a href={t('RealEstateStakingMediumLink')} target="_blank" className='stakingToken'> {t('RealEstateStaking')}</a>
                              </div>

                    </div>
               </div>
          </div >
     )
}

export default Properties


