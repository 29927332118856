import { createPublicClient, http, parseAbi, formatUnits } from 'viem';
import { base } from 'viem/chains';

class BaseScanApi {
  constructor() {
    this.client = createPublicClient({
      chain: base,
      transport: http('https://base-rpc.publicnode.com')
    });

    this.contractAddress = '0x5A7a2bf9fFae199f088B25837DcD7E115CF8E1bb';
    this.abi = parseAbi([
      'function balanceOf(address account) external view returns (uint256)',
      'function totalSupply() external view returns (uint256)',
    ]);

    this.key = 'E695ZMC4IXGCV9IPI3Q2KMY4TTAZ4W7GUT';

    this.addrs = {
      team1: '0x93429C133ab2c4A04D5d2FeeCd22D5DA7127B4E2',
      team2 : '0xAF2a042d460d3450ad4C2152491c70E8fd1f4604',
      treasury: '0x0dF0c8b86B64DFdb4A7BeA1E8B5af5Cb5Da37A31',
      liquidity_reserve : '0x5D5341923C7F261D5b6053881fD811f8BC4f8BF8',
      companies: '0x161237CECcc6a3EF3c54dBA48290cbfE39a78e1B',
      liquidity: '0xBA12222222228d8Ba445958a75a0704d566BF2C8',
      devellopement : '0xa421B97aA4305b48beBe5c9e356A5AcF0ef576f7',
      marketing : '0x859197EB8Ed69Ee1C0A7bFa78Cd50e5bf0221194',
      ecosystem : '0xa6f4eEBaa28AF404DC67aF1eda0B719F6d460e4C',
    };
  }

  async get() {
    try {
      const [team1, team2, treasury,liquidity_reserve,  companies, liquidity,devellopement,marketing,ecosystem,  totalSupply] = await Promise.all([
        this.balanceOf(this.addrs.team1),
        this.balanceOf(this.addrs.team2),
        this.balanceOf(this.addrs.treasury),
        this.balanceOf(this.addrs.liquidity_reserve),
        this.balanceOf(this.addrs.companies),
        this.balanceOf(this.addrs.liquidity),
        this.balanceOf(this.addrs.devellopement),
        this.balanceOf(this.addrs.marketing),
        this.balanceOf(this.addrs.ecosystem),


        this.totalSupply(),
      ]);

      const investors = Number(totalSupply) - (team1 + team2 + treasury + liquidity_reserve + companies + devellopement + marketing+ ecosystem+  liquidity);
      const burn = Math.trunc(20000000 - Number(totalSupply));
      const circulationSupply = Number(totalSupply) - burn;

      console.log("ecosystem is ", ecosystem)

      return {
        team1,
        team2,
        treasury,
        companies,
        liquidity: Math.trunc(liquidity),
        liquidity_reserve,
        devellopement,
        marketing,
        ecosystem: Math.trunc(ecosystem),
        investors: investors.toFixed(0),
        burn,
        circulationSupply,
      };
    } catch (e) {
      console.error('Error in get method:', e);
      throw e;
    }
  }

  async balanceOf(account) {
    try {
      const balance = await this.client.readContract({
        address: this.contractAddress,
        abi: this.abi,
        functionName: 'balanceOf',
        args: [account],
      });
      return Number(formatUnits(balance, 18));
    } catch (e) {
      console.error('Error in balanceOf method:', e);
      throw e;
    }
  }

  async totalSupply() {
    try {
      const total = await this.client.readContract({
        address: this.contractAddress,
        abi: this.abi,
        functionName: 'totalSupply',
      });
      return Number(formatUnits(total, 18));
    } catch (e) {
      console.error('Error in totalSupply method:', e);
      throw e;
    }
  }
}

export default BaseScanApi;