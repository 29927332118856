export const DynamicLocale = {
    en: {
      dyn_login: {
        title: {
          all_wallet_list: "Select your Wallet",
        },
        
      },
      dyn_user_profile:{
        my_information:{
            title: "Mes Informations",
        }
      }
    },
    fr: {
      dyn_login: {
        title: {
          all_wallet_list: "Sélectionner votre Portefeuille",
          all : "Identifiez Vous ou Inscrivez Vous",
        },
       email_form : {
        email_field: {
            label : "Entrez Votre Email",
        },
        submit_button : {
            label: "Continuer"
        }
       },
       helper:{
        all_wallet_list: "Telechrgez votre 1er Portefeuille",
        pending_connect : {
            title: "Se Connecter à votre Portefeuille"
        }
       },
       connect_wallet:{
        title: "Se Connecter"
      }
      },
      dyn_wallet_list : {
        view_all: "Voir Tout les Portefeuilles",
        search: {
            label : "Recherchez un Portefeuille",
        },
        wallet_missing:{
            title: "Vous ne voyez pas votre Portefeuille ?",
            description : "Essayer Rechercher un Portefeuille"
        }
      },
      dyn_pending_connection : {
        computer: "Cliquez sur se Connecter dans votre Portefeuille",
      },
      dyn_network_not_supported:{
        title: "Changez de Réseau",
        warning_message: "Ce réseau n'est pas supporté. Veuillez le changer",
        subtitle: "Votre Portefeuille n'est pas connecté au bon réseau. Veuillez le changer avant de continuer"
      },
      dyn_user_profile:{
        my_information:{
            title: "Mes Informations",
        }
      }
      

    },
  };
  